import {createPOPdf} from '../../../lib/labelGenerator.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileArrowDown} from '@fortawesome/free-solid-svg-icons';

export const GeneratePurchaseOrderPDF = props => {
  const {poNumber, poData} = props;

  // Combine the lines and expense lines into one array.
  const expenseLines =
    poData.data.expenseLines.map(expense => {
      return {
        name: expense.name,
        displayName: expense.memo,
        amount: expense.amount,
      };
    }) || [];
  const itemLines =
    poData.data.lines.map(item => {
      return {
        name: item.item.number,
        revision: item.revision,
        displayName: item.displayName,
        quantity: item.qty.ordered,
        shipWindow: item.shipWindow,
        masterPackQuantity: item.masterPack.quantity,
        packQuantity: item.pack.quantity,
        toteQuantity: item.tote.quantity,
        rateUSD: item.rateUSD,
        amount: item.amount,
      };
    }) || [];

  const lines = itemLines.concat(expenseLines);

  return (
    <a
      href="#"
      onClick={() => {
        createPOPdf({
          poNumber,
          ...poData.data,
          lines,
          filename: `Tekton_${poNumber}`,
        });
        // This is so the website URL does not gain the #
        event.preventDefault();
      }}
    >
      <FontAwesomeIcon icon={faFileArrowDown} /> Download
    </a>
  );
};
