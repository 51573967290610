import {Table} from '../../modules/components/table/Table.js';
import {createVRAPdf} from '../../lib/labelGenerator.js';
import Layout from '../../modules/components/layout/Layout.js';
import {useGetRequest} from '../../modules/hooks/https-hooks.js';

/*
 Note: Vendor Credit Request (VCR) are the same thing as Vendor Return Authorization (VRA)
 Internally, we use VRA, but to the vendor VCR makes more sense.
 */

/**
 * This displays the VRA Table for it's stand alone page.
 * @returns {JSX.Element}
 * @constructor
 */
const OverviewVRATable = () => {
  return (
    <Layout>
      <h1>Vendor Credit Requests List</h1>
      <VRATable pageSize={20} title={'Credit Requests'}></VRATable>
    </Layout>
  );
};

/**
 * Defines a table for displaying VRAs.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const VRATable = props => {
  const {result, hasFinished} = useGetRequest('/api/v1/ns/getAllVRAs');

  /**
   * Create a PDF for a given line.
   * @param {Object} lineData
   * @param {boolean} clicked
   * @param {function} setClicked
   */
  const generateVRAPDF = (lineData, clicked, setClicked) => {
    const url = `/api/v1/ns/vra/${lineData.tranid}`;
    fetch(url, {method: 'GET'})
      .then(response => {
        // Check if the response result was ok (true if code is 200-299)
        if (response.ok) {
          // If the result was ok, parse it and store the data.
          response
            .json()
            .then(res => {
              createVRAPdf({
                ...res.data,
                vraData: lineData,
                filename: `Tekton_${lineData.tranid}`,
              });
            })
            .then(() => {
              setClicked(false);
            });
        } else {
          // If the result was not ok then store an empty object.
          setClicked(false);
        }
      })
      .catch(() => {});
  };

  return (
    <Table
      pageSize={props.pageSize}
      header={
        <tr className="header" key="header">
          <th>VRA Number</th>
          <th>Date</th>
          <th>Memo</th>
          <th className={'text-center'}>Status</th>
          <th className="text-center">
            Confirmed
            <br />
            by vendor
          </th>
          <th className={'text-center'}>PDF</th>
        </tr>
      }
      data={result?.data || {}}
      hasDataLookupFinished={hasFinished}
      title={props.title}
      lineFormat={[
        {
          key: 'tranid',
          header: 'VRA NUMBER',
          type: 'text',
          align: 'left',
        },
        {
          key: 'trandate',
          header: 'DATE',
          type: 'date',
          align: 'left',
        },
        {
          key: 'memo',
          header: 'MEMO',
          type: 'text',
          align: 'left',
        },
        {
          key: 'status',
          header: `STATUS`,
          type: 'badge',
          align: 'center',
          options: {
            Credited: [
              {
                value: 'CREDITED',
                color: 'bg-green',
              },
            ],
            Pending: [
              {
                value: 'PENDING',
                color: 'bg-darkorange',
              },
            ],
          },
        },
        {
          key: 'confirmed',
          header: (
            <>
              CONFIRMED
              <br />
              BY VENDOR
            </>
          ),
          type: 'confirmationbutton',
          align: 'center',
          options: {
            url: '/api/v1/ns/confirm/mainline/vra',
            payload: {key: 'tranid'},
          },
        },
        {
          key: 'vrapdfbutton',
          header: 'PDF',
          type: 'button',
          align: 'center',
          options: {
            onClick: generateVRAPDF,
            label: {
              clicked: 'Generating...',
              notClicked: 'View PDF',
            },
          },
        },
      ]}
      searchFilters={[
        {
          key: 'tranid',
          type: 'string',
        },
        {
          key: 'trandate',
          type: 'date',
        },
      ]}
      sortCriteria={[
        {
          key: 'tranid',
          type: 'string',
          direction: 'descending',
        },
      ]}
      checkboxFilters={[]}
    ></Table>
  );
};

export default OverviewVRATable;
