import Layout from '../../modules/components/layout/Layout.js';
import {Table} from '../../modules/components/table/Table.js';
import {useGetRequest} from '../../modules/hooks/https-hooks.js';
import {ITEM_PRIORITY_BADGES} from '../../modules/configuration/itemPriorityBadges.js';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ItemDetail = props => {
  const itemName = props.location.pathname.split('/').pop();

  const {result, hasFinished} = useGetRequest(`/api/v1/ns/item/${itemName}`);

  let dataLines = {};
  for (const line of Object.values(result?.data || {})) {
    dataLines = {
      ...dataLines,
      ...line.lines,
    };
  }

  return (
    <Layout>
      <h1>Item {itemName}</h1>

      <Table
        pageSize={Infinity}
        data={dataLines}
        hasDataLookupFinished={hasFinished}
        title="Lines"
        lineFormat={[
          {
            key: 'transactionNumber',
            header: 'Purchase Order',
            type: 'text',
            align: 'left',
          },
          {
            key: 'expectedShipDate',
            header: 'Expected Ship Date',
            type: 'date',
            align: 'left',
          },
          {
            key: 'quantity',
            header: 'Ordered',
            type: 'text',
            align: 'center',
          },
          {
            key: 'quantityShipped',
            header: 'Shipped',
            type: 'text',
            align: 'center',
          },
          {
            key: 'quantityOutstanding',
            header: (
              <>
                Quantity
                <br />
                Outstanding
              </>
            ),
            type: 'text',
            align: 'center',
          },
          {
            key: 'priority',
            header: 'Priority',
            type: 'badge',
            align: 'center',
            options: ITEM_PRIORITY_BADGES,
          },
        ]}
        lineSettings={{
          to: {
            url: '/purchaseorder/',
            keyToAppend: 'transactionNumber',
          },
        }}
        searchFilters={[
          {
            key: 'transactionNumber',
            type: 'string',
          },
          {
            key: 'expShipDate',
            type: 'date',
          },
        ]}
        sortCriteria={[
          {
            key: 'priority',
            type: 'number',
            direction: 'ascending',
          },
          {
            key: 'expShipDate',
            type: 'date',
            direction: 'ascending',
          },
          {
            key: 'transactionNumber',
            type: 'string',
            direction: 'ascending',
          },
        ]}
      ></Table>
    </Layout>
  );
};

export default ItemDetail;
