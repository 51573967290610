import {useState} from 'react';
import Layout from '../../modules/components/layout/Layout.js';
import * as Elements from '../../modules/components/elements.js';
import DataField from '../../modules/components/DataField.js';
import auth from '../../auth.js';
import $ from 'jquery';
import {Select} from '../../modules/components/Select.js';
import Section from '../../modules/components/Section.js';
import {Button} from '../../modules/components/Button.js';

function Account() {
  const [accountData, setAccountData] = useState(null);
  const [isAccountLookupComplete, setAccountLookupComplete] = useState(false);
  const [impersonateData, setImpersonateData] = useState([]);
  const [currentImpersonation, setCurrentImpersonation] = useState(null);
  const [isImpersonateListStarted, setImpersonateListStarted] = useState(false);
  let accountElements = <div>Loading...</div>;

  if (!isAccountLookupComplete) {
    $.get({url: '/api/v1/ns/user'})
      .then(results => {
        setAccountLookupComplete(true);
        setAccountData(results);
      })
      .catch(() => {});
  }

  if (!isImpersonateListStarted) {
    setImpersonateListStarted(true);
    $.get({url: '/api/v1/impersonate/impersonateList'})
      .then(results => {
        if (results.success) {
          setImpersonateData(results.impersonateAccountList);
          setCurrentImpersonation(results.currentImpersonation);
        }
      })
      .catch(() => {});
  }

  if (accountData) {
    const formattedAddress = (
      <div>
        <div>{accountData.data.address.line1}</div>
        <div>{accountData.data.address.line2}</div>
        <div>{accountData.data.address.line3}</div>
        <div>
          {accountData.data.address.city}, {accountData.data.address.state}{' '}
          {accountData.data.address.zip}
        </div>
        <div>{accountData.data.address.country}</div>
      </div>
    );

    accountElements = (
      <div className={'flex flex-wrap content-start gap-2.5'}>
        <DataField label="Name" value={accountData.data.name} />
        <DataField label="Address" value={formattedAddress} />
      </div>
    );
  }

  const selectHandleChange = value => {
    return $.post({
      url: '/api/v1/impersonate/impersonateChange',
      data: {newImpersonationId: value},
    }).then(result => {
      setCurrentImpersonation(value);
      console.log('Now impersonating', value);
      // Set this to false so that updated data is fetched.
      setAccountLookupComplete(false);
      return new Promise(resolve => resolve(result));
    });
  };

  let options = [];
  for (const option of impersonateData) {
    options.push({
      value: option.id,
      key: option.id,
      label: `${option.vendor_name} - ${option.username}`,
    });
  }

  options = options.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    } else if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  // Only display the impersonation section if impersonate data is present.
  const impersonationSection = !options.length ? (
    ``
  ) : (
    <Section title="Impersonation Mode">
      <Select
        options={options}
        handleChange={selectHandleChange}
        defaultValue={currentImpersonation}
      ></Select>
    </Section>
  );

  return (
    <Layout>
      <h1>Account</h1>
      <Section title="Details">{accountElements}</Section>
      <Section title="Password">
        <Elements.ChangePasswordForm />
      </Section>
      <Section title="Log Out">
        <Button
          onClick={() => {
            auth.logout().then(() => {
              window.location.href = '/login';
            });
          }}
        >
          Log Out
        </Button>
      </Section>
      {impersonationSection}
    </Layout>
  );
}

export default Account;
