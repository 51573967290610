import {Table} from '../../modules/components/table/Table.js';
import Layout from '../../modules/components/layout/Layout.js';
import {useGetRequest} from '../../modules/hooks/https-hooks.js';
import {STATUS_BADGES} from '../../modules/configuration/statusBadges.js';

/**
 * This displays the PK Table for it's stand alone page.
 * @returns {JSX.Element}
 * @constructor
 */
const OverviewPKTable = () => {
  return (
    <Layout>
      <h1>Purchase Contract List</h1>
      <PKTable pageSize={20} title={'Purchase Contracts'}></PKTable>
    </Layout>
  );
};

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PKTable = props => {
  const {result, hasFinished} = useGetRequest('/api/v1/ns/pk/all');

  return (
    <Table
      pageSize={props.pageSize}
      data={result?.data || {}}
      hasDataLookupFinished={hasFinished}
      title={props.title}
      lineFormat={[
        {
          key: 'transactionNumber',
          header: 'PK NUMBER',
          type: 'text',
          align: 'left',
        },
        {
          key: 'manufacturer',
          header: 'MANUFACTURER',
          type: 'text',
          align: 'left',
        },
        {
          key: 'memo',
          header: 'Memo',
          type: 'text',
          align: 'left',
        },
        {
          key: 'startDate',
          header: 'Start Date',
          type: 'date',
          align: 'left',
        },
        {
          key: 'endDate',
          header: 'End Date',
          type: 'date',
          align: 'left',
        },
        {
          key: 'status',
          header: `STATUS`,
          type: 'badge',
          align: 'center',
          options: STATUS_BADGES,
        },
      ]}
      lineSettings={{
        to: {
          url: '/purchasecontract/',
          keyToAppend: 'transactionNumber',
        },
      }}
      searchFilters={[
        {
          key: 'transactionNumber',
          type: 'string',
        },
        {
          key: 'manufacturer',
          type: 'string',
        },
      ]}
      sortCriteria={[
        {
          key: 'transactionNumber',
          type: 'string',
          direction: 'descending',
        },
      ]}
      checkboxFilters={[
        {
          key: 'status',
          condition: {
            type: 'equals',
            values: 'Needs Review',
          },
          label: 'Needs Review Only',
          value: false,
        },
        {
          key: 'status',
          condition: {
            type: 'anyof',
            values: ['Needs Review', 'Confirmed', 'Rejected'],
          },
          label: 'Unexpired Only',
          value: false,
        },
      ]}
    ></Table>
  );
};

export default OverviewPKTable;
